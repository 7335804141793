.containerSearch {
  flex-direction: column;
}

.fieldSearch {
  margin: 0;
}

.dropdown-menu {
  position: absolute;
  transform: translateY(4px);
  width: 20rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  max-height: 150px;
  background-color: #fff;
  z-index: 2;
}
.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-item {
  padding: 15px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #9fc3f870;
}
.dropdown-item.selected {
  background-color: #0d6efd;
  color: #fff;
}

.inputIcon {
  height: 56px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 20rem;
  align-items: center;
  display: flex;
  padding: 5px;
}

.input {
  height: 50px;
  padding: 10px;
  border: transparent;
  width: 27rem;
  outline: none;
  font-size: 16px;
  font-family: inherit;
}

.buttonKeyboard {
  background-color: transparent;
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  outline: none;
}

.buttonKeyboard:hover {
  background-color: rgb(231, 230, 230);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

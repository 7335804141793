.btnCadastro {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.containerFields {
  display: flex;
  margin: 10px;
  gap: 20px;
  width: 90%;
}
